table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
}

.normal th,
td {
  background-color: #8fdab4;
  font-size: 12px !important;
  width: 40px;
  height: 18px;
  text-align: center;

}

.sunday {
  color: red;
}

td:hover {background-color: silver;}